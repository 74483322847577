// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---marketing-extension-theme-src-pages-404-tsx": () => import("./../../marketing-extension-theme/src/pages/404.tsx" /* webpackChunkName: "component---marketing-extension-theme-src-pages-404-tsx" */),
  "component---marketing-extension-theme-src-pages-index-tsx": () => import("./../../marketing-extension-theme/src/pages/index.tsx" /* webpackChunkName: "component---marketing-extension-theme-src-pages-index-tsx" */),
  "component---marketing-extension-theme-src-pages-install-tsx": () => import("./../../marketing-extension-theme/src/pages/install.tsx" /* webpackChunkName: "component---marketing-extension-theme-src-pages-install-tsx" */),
  "component---marketing-extension-theme-src-pages-uninstall-tsx": () => import("./../../marketing-extension-theme/src/pages/uninstall.tsx" /* webpackChunkName: "component---marketing-extension-theme-src-pages-uninstall-tsx" */)
}

