/* @flow */
export default {
    colors: {
        primary: '#fff',
        secondary: '#f9f885',
        background: '#ec6c62',
        text: '#fff',
        modes: {
            morning: {
                background: '#ec6c62',
                secondary: '#f9f885'
            },
            afternoon: {
                background: '#1f8598',
                secondary: '#76ffa7'
            },
            evening: {
                background: '#735684',
                secondary: '#ffb0da'
            }
        }
    },

    fonts: {
        body: 'Montserrat',
        heading: 'Montserrat',
        fontSizes: [12]
    },
    fontWeights: {
        body: 400,
        bold: 700
    },
    text: {
        default: {
            color: 'text',
            fontFamily: 'body'
        },
        heading: {
            fontFamily: 'body',
            fontSize: '36px',
            fontWeight: '500',
            color: 'text',
            margin: '60px'
        },
        paragraph: {
            color: '#7b7b7b',
            fontweight: 500,
            letterSpacing: '0.78px',
            textTransform: 'uppercase',
            fontSize: '14px',
            lineHeight: '1.86',
            fontFamily: 'body',
            fontWeight: 500
        },
        bold: {
            fontWeight: 'bold',
            color: 'text',
            fontFamily: 'body'
        },
        labelLight: {
            fontSize: '14px',
            fontFamily: 'body',
            fontWeight: '100',
            color: 'rgba(255, 255, 255, .8)'
        },
        label: {
            fontFamily: 'body',
            color: 'text',
            fontSize: '24px',
            fontWeight: '800'
        },
        description: {
            fontFamily: 'body',
            color: 'label',
            fontSize: '16px',
            maxWidth: '500px',
            lineHeight: '1.56',
            textAlign: 'center'
        },
        quote: {
            color: 'secondary',
            fontFamily: 'body',
            fontSize: '4vw',
            fontWeight: '800',
            lineHeight: '1.09',
            textAlign: 'center'
        }
    },

    variants: {
        bodySpacing: {
            flex: '1 1 120px'
        }
    },

    links: {
        footer: {
            fontFamily: 'body',
            color: 'text',
            fontSize: '14px',
            letterSpacing: '.39px',
            textDecoration: 'underline',
            fontWeight: 'bold'
        }
    },
    layout: {
        header: {
            display: 'flex',
            padding: '47px 62px 0'
        },
        main: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: '20px'
        },
        footer: {
            display: 'none'
        }
    }
};
